<template>
    <div class="disclaimer-manage">
        <div class="inner">
            <div class="display-section">
                <div class="block">
                    <div class="label">For 分享案例的醫師</div>
                    <div class="editor">
                        <tinymce
                            v-model="shareContent"
                            model-events="change"
                            :init="initOptions"
                        />
                    </div>
                    <div class="actions">
                        <button-wrapper
                            class="btn"
                            type="modal"
                            color="normal"
                            @click="onShareRemove"
                        >
                            清空
                        </button-wrapper>
                        <button-wrapper
                            class="btn"
                            type="modal"
                            color="primary"
                            @click="onShareSave"
                        >
                            儲存
                        </button-wrapper>
                    </div>
                </div>
                <div class="block">
                    <div class="label">For 瀏覽案例的醫師</div>
                    <div class="editor">
                        <tinymce
                            v-model="viewContent"
                            model-events="change"
                            :init="initOptions"
                        />
                    </div>
                    <div class="actions">
                        <div class="actions">
                            <button-wrapper
                                class="btn"
                                type="modal"
                                color="normal"
                                @click="onViewRemove"
                            >
                                清空
                            </button-wrapper>
                            <button-wrapper
                                class="btn"
                                type="modal"
                                color="primary"
                                @click="onViewSave"
                            >
                                儲存
                            </button-wrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div class="policy-box">
                <div
                    class="item reveal-information"
                    @click="onOpenRevealInformationPopup"
                >
                    羅氏揭露訊息
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/link';
import 'tinymce/plugins/template';
import 'tinymce/plugins/table';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/toc';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/noneditable';
import { mapMutations, mapActions } from 'vuex';

export default {
    components: {
        Tinymce: () => import('@tinymce/tinymce-vue'),
    },
    data() {
        return {
            shareContent: "",
            viewContent: "",
            initOptions: {
                relative_urls: false,
                plugins: [
                    'advlist autolink lists link charmap',
                    'searchreplace visualblocks',
                    'insertdatetime table'
                ],
                toolbar: [
                    'undo redo | formatselect | ' +
                    'bold italic underline forecolor backcolor | fontsizeselect formatselect | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat ',
                ],
                language: 'zh_TW',
                language_url: '/tinymce/lang/zh_TW.js',
                fontsize_formats: '12px 14px 16px 18px 20px 24px 36px 48px 56px 72px',
                font_formats:
                    '思源黑體=Noto Sans TC;Volte-Medium=Volte-Medium;Volte-Semibold=Volte-Semibold;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
                content_css: '/tinymce/css/index.css',
                min_height: 300,
                statusbar: false,
                init_instance_callback: this.onEditorInit,
                setup: (editor) => {
                    editor.on('init', () => {
                        this.loading = false;
                        this.$emit('loaded');
                    });
                },
            },
        };
    },
    computed: {
    },
    created() {
        this.getDisclaimer()
    },
    mounted() { },
    methods: {
        ...mapMutations({
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        getDisclaimer() {
            this.getShareDisclaimer()
            this.getViewDisclaimer()
        },
        async getShareDisclaimer() {
            const PAYLOAD = {
                category: 'ShareDisclaimer',
                function: 'fetch',
                hideMessage: true
            };
            let result = await this.apiClient(PAYLOAD);
            this.shareContent = result.data.content
        },
        async getViewDisclaimer(){
            const PAYLOAD = {
                category: 'ViewDisclaimer',
                function: 'fetch',
                hideMessage: true
            };
            let result = await this.apiClient(PAYLOAD);
            this.viewContent = result.data.content
        },
        async onShareSave() {
            const PAYLOAD = {
                category: 'ShareDisclaimer',
                function: 'create',
                successMsg: '成功更新分享案例免責聲明',
                data: {
                    content: this.shareContent
                }
            };
            await this.apiClient(PAYLOAD);
        },
        onShareRemove() {
            this.shareContent = ""
        },
        async onViewSave() {
            const PAYLOAD = {
                category: 'ViewDisclaimer',
                function: 'create',
                successMsg: '成功更新瀏覽案例免責聲明',
                data: {
                    content: this.viewContent
                }
            };
            await this.apiClient(PAYLOAD);
        },
        onViewRemove() {
            this.viewContent = ""
        },
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
    },
};
</script>

<style lang="scss" scoped>
.inner {
    width: 93%;
    height: 100%;
}
.display-section {
    box-sizing: border-box;
    width: 100%;
    height: 88%;
    border-radius: 12px;
    padding: 20px;
    background: $list-page-background;
    overflow-y: scroll;
}

.actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    .btn {
        margin-left: 18px;
    }
}

.block {
    margin-bottom: 25px;
    .label {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
    }
}
</style>
